import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { map } from 'rxjs/operators';
import { Dekopartner } from '../model/dekopartner';
import { DekopartnerMitarbeiter } from '../model/dekopartner.mitarbeiter';
import { SimpleResponse } from '../model/simpleResponse';

@Injectable({
  providedIn: 'root'
})
export class DekopartnerService {

  constructor(private apiService: ApiService) { }

  public getDekopartner() : Observable<Dekopartner[]> {
    return this.apiService.get("/dekopartner")
      .pipe(
        map(
          res => res['data']
          ),
        map( (items:[]) =>
              items.map(item => 
                ({nr: item['NR'],
                  name: item['NAME']
                } as Dekopartner
                )
              )
            )
      )       
  }

  public getDekopartnerMitarbeiter(dekopartner: number, onlyaktiv:boolean, onlymitlogin:boolean) : Observable<DekopartnerMitarbeiter[]> {
    return this.apiService.get("/dekopartnermitarbeiter")
      .pipe(
        map(
          res => res['data']
          ),
        map( (items:[]) =>
              items.filter(item => ( ((item['DEKOPARTNER_REF']==dekopartner) ||
                                     (item['DEKOPARTNER2_REF']==dekopartner) ||
                                     (item['DEKOPARTNER3_REF']==dekopartner)) &&
                                     (!onlyaktiv || (item['STATUS']==0)) &&
                                     (!onlymitlogin || (item['HATLOGIN']==1))
                                   ))
                   .map(item => 
                ({nr: item['NR'],
                vorname: item['VORNAME'],
                name: item['NAME'],
                dekopartner: item['DEKOPARTNER_REF'],
                dekopartner2: item['DEKOPARTNER2_REF'],
                dekopartner3: item['DEKOPARTNER3_REF'],
                inaktiv: item['STATUS']!=0,
                email: item['DPM_EMAIL'],
                dpFreigabe: item['DP_FREIGABE'],
                dpFreigabeName: item['DP_FREIGABENAME'],
                hatLogin: item['HATLOGIN']==1,
                kuerzel: item['MA']
                } as DekopartnerMitarbeiter
                )
              )
            )
      )       
  }

  public getAlleDekopartnerMitarbeiter(onlyaktiv:boolean) : Observable<DekopartnerMitarbeiter[]> {
    return this.apiService.get("/dekopartnermitarbeiter")
      .pipe(
        map(
          res => res['data']
          ),
        map( (items:[]) =>
              items.filter(item => ( (!onlyaktiv || (item['STATUS']==0))
                                   ))
                   .map(item => 
                ({nr: item['NR'],
                vorname: item['VORNAME'],
                name: item['NAME'],
                dekopartner: item['DEKOPARTNER_REF'],
                dekopartner2: item['DEKOPARTNER2_REF'],
                dekopartner3: item['DEKOPARTNER3_REF'],
                inaktiv: item['STATUS']!=0,
                email: item['DPM_EMAIL'],
                dpFreigabe: item['DP_FREIGABE'],
                dpFreigabeName: item['DP_FREIGABENAME'],
                hatLogin: item['HATLOGIN']==1,
                kuerzel: item['MA']
                } as DekopartnerMitarbeiter
                )
              )
            )
      )       
  }

  public getMitarbeiterStatus(mitarbeiter:DekopartnerMitarbeiter):string {
    return mitarbeiter.inaktiv ? "Inaktiv" : "aktiv";
  }

  public getMitarbeiterLoginErlaubt(mitarbeiter:DekopartnerMitarbeiter):string {
    return mitarbeiter.hatLogin ? "Ja" : "Nein";
  }

  // die Geheimnisvereinbarung für einen Mitarbeiter bestätigen
  public sendeDPMitarbeiterZustimmung(mitarbeiter:DekopartnerMitarbeiter): Observable<SimpleResponse> {

    return this.apiService.post('/dekopartnermitarbeiter/'+mitarbeiter.nr+'/zustimmung');

  }

}
