import { Injectable } from '@angular/core';
import { User } from '../../core/model/user';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { ApiService } from './api.service';


@Injectable({
  providedIn: 'root'
})
export class UserService {

  private _currentUserSubject: BehaviorSubject<User | null>;
  private _isloggedIn:BehaviorSubject<boolean>=new BehaviorSubject<boolean>(false);

  public currentUser: Observable<User | null>;

  constructor(private http: HttpClient, private router:Router, private apiService: ApiService) { 

    let user= null;
    let item= localStorage.getItem('loggedinUser');
    if (item) {
      user = Object.assign(User.Empty(), JSON.parse(item));
    }  

    this._currentUserSubject = new BehaviorSubject<User | null>(user);
    this.currentUser = this._currentUserSubject.asObservable();

  }

  public loginUserByUid(uid:string): void {
    console.log('UserService login by Uid');
    let user= new User(BigInt(0),'Dekomanager-Login',User.USER_FUNKTION_WITHOUTLOGIN, '', uid);
    this._currentUserSubject.next(user);
    this._isloggedIn.next(true);
    this.getUserData();
  }

  public get currentUserValue(): User | null {
    return this._currentUserSubject.value;
  }

  public get isAuthenticated$(): Observable<boolean> {
    return this._isloggedIn.asObservable();
  }

  public logout():void {
    this._isloggedIn.next(false);
    this._currentUserSubject.next(null);
    localStorage.removeItem('loggedinUser');
  }

  public login(username:string, password:string):BehaviorSubject<boolean> {

    console.log('Service Starting login');
    let lr: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
   
    const body = new HttpParams()
    .set('User', username)
    .set('Password', password);

    this.http.post(environment.apiUrl+'/login',
      body.toString(),
    {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/x-www-form-urlencoded')
    }
    ).subscribe({
      next: (userResponse) => this.processLoginResponse(userResponse, lr),
      error: (e) => { console.error(e); lr.error('Server nicht erreichbar.') }, 
      complete: () => lr.complete() 
    });
    return lr;    
  }

  private processLoginResponse(userResponse:any, lr:BehaviorSubject<boolean>):void {
    if (userResponse.success) {
      console.log(userResponse.result);

      let user= new User(userResponse.result.NR, userResponse.result.NAME,userResponse.result.FUNKTION, userResponse.result.LOGEMAIL, userResponse.result.TOKEN);
      localStorage.setItem('loggedinUser', JSON.stringify(user));
      this._currentUserSubject.next(user);
      this._isloggedIn.next(true);
      lr.next(true);
    }  
    else {
      console.log('failed');
      console.log(userResponse.ErrorMessage);
      localStorage.removeItem('loggedinUser');
      this._currentUserSubject.next(null);
      this._isloggedIn.next(false);
      lr.error(userResponse.ErrorMessage);
    }    
  }

  private getUserData():BehaviorSubject<boolean> {
    console.log('Service Starting getUserData');
    let lr: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    this.apiService.get("/user").subscribe({
      next: (userResponse) => this.processUserDataResponse(userResponse, lr),
      error: (e) => { console.error(e); lr.error('Server nicht erreichbar.') }, 
      complete: () => lr.complete() 
    });
    return lr;        
  } 

  private processUserDataResponse(userResponse:any, lr:BehaviorSubject<boolean>):void {
    if (userResponse.success && this.currentUserValue) {
      console.log(userResponse.result);
      let token:string= this.currentUserValue?.token;
      let user= new User(userResponse.result.NR, userResponse.result.NAME,userResponse.result.FUNKTION, userResponse.result.LOGEMAIL, token);
      user.demaUser= true;
      this._currentUserSubject.next(user);
      this._isloggedIn.next(true);
      lr.next(true);
    }  
    else {
      console.log('failed');
      console.log(userResponse.ErrorMessage);
      localStorage.removeItem('loggedinUser');
      this._currentUserSubject.next(null);
      this._isloggedIn.next(false);
      lr.error(userResponse.ErrorMessage);
    }    
  }

  public checkTokenValid() {
    // redirected auf die Login-Seite, wenn der Server auf den Token-Request mit einem Fehler antwortet.
    let user:User|null = this.currentUserValue;
    if (!user)
      return;

    this.http.get(environment.apiUrl+'/token?token='+user.token)
    .subscribe(
      { next : (resp:any) => { if (!resp.success) { 
                              console.log('checktoken logout:',resp);
                              this.logout();
                              this.router.navigate(['/login']);
                             } 
                         },
        error: (err) => this.logout()
      });

  }

  public canFragebogenPermalink() :boolean {
    if (!this.currentUserValue)
      return false;

    return (this.currentUserValue.funktion==User.USER_FUNKTION_SUPERVISOR);
  }


}
