<h2 mat-dialog-title>Neue Nachricht erfassen</h2>
<form [formGroup]="form" (ngSubmit)="formSenden(form)">
  <mat-dialog-content>

    <mat-error *ngIf="errorMessage!=''">
      {{errorMessage}}
     </mat-error>

    <div class="container"> 
    <mat-form-field>
        <mat-select  (selectionChange)="katChange()"
                placeholder="Art"
                formControlName="kategorie">
                <mat-option *ngFor="let katlabel of katlabels" [value]="katlabel.nr">
                    {{katlabel.bezeichnung}}
                  </mat-option>
                </mat-select>
    </mat-form-field>
    </div>

    <div class="container" *ngIf="([1,2].includes(form.get('kategorie')?.value))"> 
      <mat-form-field name="selauftrag">
          <mat-select (selectionChange)="auftragChange()"
                  placeholder="Auftrag"
                  formControlName="auftrag">
                  <mat-option *ngFor="let a of auftragsliste" [value]="a.nr">
                      {{a.auftragsnummer+'-'+a.bezeichnung}}
                    </mat-option>
                  </mat-select>
      </mat-form-field>
      </div>

    <div class="container" *ngIf="(form.get('kategorie')?.value==2)"> 
        <mat-form-field name="seldekobeleg">
            <mat-select 
                    placeholder="Dekobeleg"
                    formControlName="dekobeleg">
                    <mat-option *ngFor="let b of belegliste" [value]="b.nr">
                        {{b.belegnummer+'-'+b.dsName+', '+b.dsStrasse+', '+b.dsOrt}}
                      </mat-option>
                    </mat-select>
        </mat-form-field>
        </div>
  
    <div class="container" *ngIf="(form.get('kategorie')?.value==3) || (form.get('kategorie')?.value==4)"> 
      <mat-form-field name="seldekopartner">
          <mat-select (selectionChange)="dpChange()"
                  placeholder="Dekopartner"
                  formControlName="dekopartner">
                  <mat-option *ngFor="let dp of dpliste" [value]="dp.nr">
                      {{dp.nr+'-'+dp.name}}
                    </mat-option>
                  </mat-select>
      </mat-form-field>
      </div>

      <div class="container" *ngIf="form.get('kategorie')?.value==4"> 
        <mat-form-field name="seldpmitarbeiter">
            <mat-select
                    placeholder="Dekopartner-Mitarbeiter"
                    formControlName="dekopartnermitarbeiter">
                    <mat-option *ngFor="let dpm of dpmaliste" [value]="dpm.nr">
                        {{(dpm.vorname+' '+dpm.name.trim())}}
                      </mat-option>
                    </mat-select>
        </mat-form-field>
        </div>
    
    <div class="container">
    <mat-form-field>
        <input matInput
                placeholder="Titel"
                formControlName="titel">
    </mat-form-field>
    </div>

    <div class="container">
        <mat-form-field>
        <textarea matInput cdkTextareaAutosize
        #autosize="cdkTextareaAutosize"
        cdkAutosizeMinRows="5"
        cdkAutosizeMaxRows="5"
                placeholder="Text"
                formControlName="text"></textarea>
    </mat-form-field>      
    </div>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-dialog-close class="mat-raised-button" (click)="formAbort()">Abbrechen</button>
    <button type="submit" class="mat-raised-button mat-primary">Senden</button>
</mat-dialog-actions>
</form>
