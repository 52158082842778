<h1>Nachrichten</h1>
<p>
<button mat-raised-button (click)="neueNachricht()">
   Neue Nachricht
 </button>
</p>
<table mat-table [dataSource]="datasource" class="mat-elevation-z8">

   <!--- Note that these columns can be defined in any order.
         The actual rendered columns are set as a property on the row definition" -->
 
   <!-- Position Column -->
   <ng-container matColumnDef="titel">
     <th mat-header-cell *matHeaderCellDef> Titel </th>
     <td mat-cell *matCellDef="let element" data-label="Titel"> {{element.titel}}<br>{{element.text}} </td>
   </ng-container>
   <ng-container matColumnDef="gesendetAm">
      <th mat-header-cell *matHeaderCellDef> gesendet </th>
      <td mat-cell *matCellDef="let element" data-label="gesendet am"> {{element.gesendetAm | date:'medium' }} </td>
    </ng-container>
    <ng-container matColumnDef="absenderName">
      <th mat-header-cell *matHeaderCellDef> Absender </th>
      <td mat-cell *matCellDef="let element" data-label="Absender"> {{element.absenderName}} </td>
    </ng-container>
  
   <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
   <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
 </table>
 <mat-paginator #paginator [pageSizeOptions]="[20, 50, 100, 500]"
 showFirstLastButtons 
 aria-label="Select page of periodic elements">
</mat-paginator>
