<mat-card>
    <mat-card-content>
      <form #loginForm="ngForm" (ngSubmit)="onSubmit()">
        <h2>Benutzeranmeldung</h2>
        <section>
          Bitte geben Sie Ihren Benutzernamen und Ihr Passwort an, um Zugang zu Ihren Daten zu erhalten. 
        </section>
        <mat-error *ngIf="error!=''">
         {{error}}
        </mat-error>
        <mat-form-field>
          <input matInput placeholder="Benutzername" [(ngModel)]="username" name="username" required>
          <mat-error>
            Bitte gib einen gültigen Benutzernamen ein
          </mat-error>
        </mat-form-field>
        <mat-form-field>
          <input matInput type="password" placeholder="Password" [(ngModel)]="password" name="password" required>
          <mat-error>
            Bitte gib ein gültiges Passwort ein
          </mat-error>
        </mat-form-field>
        <button mat-raised-button color="primary" [disabled]="!loginForm.form.valid || loading">Login</button>
      </form>
    </mat-card-content>
  </mat-card>