import { Component, OnInit } from '@angular/core';
import { FormControl, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Auftrag } from 'src/app/core/model/auftrag';
import { DekoBeleg } from 'src/app/core/model/dekoBeleg';
import { Dekopartner } from 'src/app/core/model/dekopartner';
import { DekopartnerMitarbeiter } from 'src/app/core/model/dekopartner.mitarbeiter';
import { Nachricht } from 'src/app/core/model/nachricht';
import { AuftraegeService } from 'src/app/core/services/auftraege.service';
import { DekobelegeService } from 'src/app/core/services/dekobelege.service';
import { DekopartnerService } from 'src/app/core/services/dekopartner.service';
import { NachrichtenService } from 'src/app/core/services/nachrichten.service';
import { UserService } from 'src/app/core/services/user.service';
import { NachrichtenKategorienDP, NachrichtenKategorienSup, NachrichtenKategorieTag, NachrichtKategorie } from '../../../core/model/nachricht.kategorien';

@Component({
  selector: 'app-nachrichten-neu',
  templateUrl: './nachrichten-neu.component.html',
  styleUrls: ['./nachrichten-neu.component.scss']
})
export class NachrichtenNeuComponent implements OnInit {

  form: UntypedFormGroup;

  katlabels : NachrichtKategorie[];
  
  auftragsliste: Auftrag[] | null=null;
  dpliste: Dekopartner[] | null=null;
  dpmaliste: DekopartnerMitarbeiter[] | null= null;
  belegliste: DekoBeleg[] | null=null;
  errorMessage: string='';
  
  constructor(
        private fb: UntypedFormBuilder,
        private dialogRef: MatDialogRef<NachrichtenNeuComponent>,
        private nachrichtenService: NachrichtenService,
        private auftraegeService: AuftraegeService,
        private dekobelegeService: DekobelegeService,
        private dekopartnerService: DekopartnerService,
        private userService: UserService) {

      if (userService.currentUserValue?.isSupervisor())
       this.katlabels= NachrichtenKategorienSup;
     else
       this.katlabels= NachrichtenKategorienDP;

     this.form= fb.group({
      kategorie: [0, Validators.required],
      auftrag: [null],
      dekobeleg: [null],
      dekopartner: [null],
      dekopartnermitarbeiter: [null],
      titel: [null, Validators.required],
      text: [null]
    });}

  ngOnInit(): void {

    this.dekopartnerService.getDekopartner().subscribe(
      { next : item => this.dpliste=item}
    )
    this.auftraegeService.getOffeneAuftraege().subscribe(
      { next : item => this.auftragsliste=item}
    )
  }

  katChange(): void {
    this.form.patchValue({auftrag: null, dekopartner: null, dekobeleg: null, dekopartnermitarbeiter: null});
    const kat= this.form.get('kategorie')?.value;
    
    if ((kat==NachrichtenKategorieTag.nkt_auftrag) || (kat==NachrichtenKategorieTag.nkt_dekobeleg)) {
      this.form.get('auftrag')?.setValidators([Validators.required]);      
    } else
      this.form.get('auftrag')?.clearValidators();
    this.form.get('auftrag')?.updateValueAndValidity();

    if ((kat==NachrichtenKategorieTag.nkt_dp) || (kat==NachrichtenKategorieTag.nkt_ma)) {
      if (this.userService.currentUserValue?.isDekopartner() && (this.dpliste) && (this.dpliste.length>0)) {
        this.form.patchValue({dekopartner: this.dpliste[0].nr});
        this.dpChange();
      }
      this.form.get('dekopartner')?.setValidators([Validators.required]);
    } else
      this.form.get('dekopartner')?.clearValidators();
    this.form.get('dekopartner')?.updateValueAndValidity();
    
    if (kat==NachrichtenKategorieTag.nkt_dekobeleg)
      this.form.get('dekobeleg')?.setValidators([Validators.required]);
    else
      this.form.get('dekobeleg')?.clearValidators();
    this.form.get('dekobeleg')?.updateValueAndValidity();
    
    if (kat==NachrichtenKategorieTag.nkt_ma)
      this.form.get('dekopartnermitarbeiter')?.setValidators([Validators.required]);
    else
      this.form.get('dekopartnermitarbeiter')?.clearValidators();
    this.form.get('dekopartnermitarbeiter')?.updateValueAndValidity();
  }

  dpChange(): void {
    this.form.get('dekopartnermitarbeiter')?.setValue(null);
    this.dekopartnerService.getDekopartnerMitarbeiter(this.form.get('dekopartner')?.value, true, true).subscribe(
      { next : (item) => {this.dpmaliste=item},
        error : () => {this.dpmaliste= null}
      }
    )
  }

  auftragChange(): void {
    this.form.get('dekobeleg')?.setValue(null);
    this.dekobelegeService.getAuftragDekobelege(this.form.get('auftrag')?.value).subscribe(
      { next : (item) => {this.belegliste=item},
        error : (error) => { console.log(error);this.belegliste= null}
      }
    )
  }


  public formSenden(form:any) {
    if (form.valid) {
      const nachricht: Nachricht = new Nachricht();
      nachricht.kategorie= form.value.kategorie;
      nachricht.titel= form.value.titel;
      nachricht.text= form.value.text;
      nachricht.wichtig= false;
      nachricht.auftrag= form.value.auftrag;
      nachricht.dekobeleg= form.value.dekobeleg;
      nachricht.dekopartner= form.value.dekopartner;
      nachricht.dekopartnermitarbeiter= form.value.dekopartnermitarbeiter;
      this.nachrichtenService.sendeNachricht(nachricht).subscribe({
        next: (response) => { if (response.success) this.dialogRef.close(true); else this.errorMessage= response.ErrorMessage; },
        error: (error) => this.errorMessage= error.ErrorMessage                
      });      
    }
  }

  public formAbort() {
    this.dialogRef.close();
  }

}
