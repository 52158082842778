import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, EMPTY, map, Observable, of } from 'rxjs';
import { DekoBeleg } from '../model/dekoBeleg';
import { User } from '../model/user';
import { ApiService } from './api.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class DekobelegeService {

  constructor(private apiService: ApiService,
    private userService: UserService) {
  }

  // kann auch permalink-ID sein.
  public getDekobeleg(belegnr:string): Observable<DekoBeleg> {
    return this.apiService.get("/dekobelege/"+belegnr)
    .pipe(
      map(
        res => res['dekobeleg']
        ),
      map( item => this.mapDekobeleg(item)),
      catchError( () => { return EMPTY })                       
    ) 
  }

  public getAuftragDekobelege(auftrag :number): Observable<DekoBeleg[]> {
    const params = new HttpParams().set('Auftrag', auftrag);
    return this.apiService.get("/dekobelege", params)
    .pipe(
      map(
        res => res['data']
        ),
      map( (items:[]) => 
            items.map(item => this.mapDekobeleg(item))
          )
    )           
  }

  public getDekobelegeMitFragebogen(): Observable<DekoBeleg[]> {
    const params = new HttpParams().set('filter', 'fragebogenoffen');
    return this.apiService.get("/dekobelege", params)
    .pipe(
      map(
        res => res['data']
        ),
      map( (items:[]) => 
            items.map(item => this.mapDekobeleg(item))
          )
    )           
  }

  private mapDekobeleg (item:any): DekoBeleg {
    if (!item) 
      return {} as DekoBeleg;
      
    return {
      nr: item['NR'],
      belegnummer: item['BELEGNUMMER'],
      auftrag: item['AUFTRAG_REF'],
      dekostelle: item['DEKOSTELLEN_REF'],
      dekopartner: item['DEKOPARTNER_REF'],
      qsAm: item['QS_AM'],
      terminAm: item['TERMIN_AM'],
      dsName: item['NAME'],
      dsName2: item['NAME2'],
      dsIntNr: item['INT_NR'],
      dsExtNr: item['EXT_NR'],
      dsExtNr2: item['EXT_NR2'],
      dsStrasse: (item['STRASSE']+' '+(item['STRASSE_HAUSNR']??'')).trim(),
      dsHausNr: item['STRASSE_HAUSNR'],
      dsOrt: item['ORT'],
      dsPlz: item['PLZ'],
      dsTelefon: item['TELEFON']
    } as DekoBeleg;
  }

  public anschriftHtml(beleg:DekoBeleg):string {
    return `${beleg.dsStrasse}\n${beleg.dsPlz} ${beleg.dsOrt}`;
  }

  public getStatus(beleg:DekoBeleg):string {
    if (beleg.reerfasstAm) {
      if (this.userService.currentUserValue?.funktion!=User.USER_FUNKTION_SUPERVISOR) 
        return 'erfasst';
      if (beleg.qsAm)
        return 'QS fertig';
      return 'in QS';
    } else {
      if (beleg.vorerfasstAm)
        return 'vorerfasst';
      return 'offen';
    }
  }

  
}
