import { Component, Inject } from '@angular/core';
import { User } from './core/model/user';
import { Auftrag } from './core/model/auftrag';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from './core/services/user.service';
import { AuftraegeService } from './core/services/auftraege.service';
import { AuthGuard } from './user/_helper/auth.guard';
import { Location } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  title = 'Dekomanager';
  sideNavOpened: boolean;
  isExpanded:boolean=true; // wird im Moment nicht verwendet (Vorbereitung für Icons der Nav immer anzeigen)

  currentUser: User | null;
  currentAuftrag: Auftrag | null;

  constructor(private location:Location, private route: ActivatedRoute, private router: Router, private userService: UserService, private auftraegeService: AuftraegeService, private authGuard:AuthGuard) {
    this.currentUser= null;
    this.currentAuftrag= null;
    this.sideNavOpened= false;
  }

  ngOnInit() {
    var url = new URL(window.location.href);
    const demaUid  = url.searchParams.get("uid");    
    if (demaUid) {
      this.userService.loginUserByUid(demaUid);      
    }
    this.userService.currentUser.subscribe(u => this.currentUser= u);
    this.auftraegeService.currentAuftrag.subscribe(a => this.currentAuftrag= a);
    console.log(this.location.path());
    this.userService.checkTokenValid();
  }

  ngOnDestroy() {
  }

  canNachrichten():boolean{
    return this.authGuard.canNachrichten();
  }
  
  canDPMitarbeiterPflege():boolean{
    return this.authGuard.canDPMitarbeiterPflege();
  }

  public logout() {
    this.userService.logout();
    this.router.navigate(['/login']);
  }

  
}
