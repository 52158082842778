import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Observable } from 'rxjs';
import { NachrichtenService } from 'src/app/core/services/nachrichten.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { NachrichtenNeuComponent } from '../nachrichten-neu/nachrichten-neu.component';
import { Nachricht } from 'src/app/core/model/nachricht';

@Component({
  selector: 'app-nachrichten-liste',
  templateUrl: './nachrichten-liste.component.html',
  styleUrls: ['./nachrichten-liste.component.scss']
})
export class NachrichtenListeComponent implements OnInit, AfterViewInit {

  _nachrichten: Observable<Nachricht[]>;
  datasource= new MatTableDataSource<Nachricht>();
  displayedColumns= ['titel', 'gesendetAm', 'absenderName'];
  @ViewChild('paginator') paginator: MatPaginator | null;

  constructor(private nachrichtenService: NachrichtenService, private dialog: MatDialog) { 
    this._nachrichten= nachrichtenService.getNachrichten();
    this.paginator= null;
  }
  ngAfterViewInit() {
    this.datasource.paginator = this.paginator;
  }

  ngOnInit(): void {
    this._nachrichten.subscribe(nachrichten => this.datasource.data= nachrichten)    
  }

  public neueNachricht() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = { 
    };

    const dialogRef = this.dialog.open(NachrichtenNeuComponent, dialogConfig);  
    
    dialogRef.afterClosed().subscribe(
      () => { // refresh der Nachrichten auslösen, falls gespeichert.
        this._nachrichten= this.nachrichtenService.getNachrichten();
        this.ngOnInit();
      }); 
  }

}
