export class DekopartnerMitarbeiter {
    nr: number= 0;
    dekopartner: number= 0;
    dekopartner2: number= 0;
    dekopartner3: number= 0;
    kuerzel:string='';
    vorname:string= '';
    name: string= '';
    inaktiv: boolean= false;
    email:string='';
    hatLogin:boolean= false;
    dpFreigabe?:Date;
    dpFreigabeName:String='';
}