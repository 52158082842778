<h1>Dekopartner-Mitarbeiter</h1>

<table mat-table matTableResponsive [dataSource]="datasource" class="mat-elevation-z8" matSort>

   <!--- Note that these columns can be defined in any order.
         The actual rendered columns are set as a property on the row definition" -->

   <!-- Position Column -->
   <ng-container matColumnDef="dekopartner">
    <tr><th mat-header-cell *matHeaderCellDef mat-sort-header> 
     <mat-form-field>      
       <input placeholder="Dekopartner" matInput [formControl]="filterDekopartner"/>
     </mat-form-field></th></tr>
     <tr><td mat-cell *matCellDef="let element" data-label="Dekopartner"> {{element.dekopartner}} </td></tr>
  </ng-container>
  <ng-container matColumnDef="kuerzel">
     <th mat-header-cell *matHeaderCellDef mat-sort-header>
      <mat-form-field >
        <input placeholder="Kürzel" matInput [formControl]="filterKuerzel"/>
      </mat-form-field>
    </th>
     <td mat-cell *matCellDef="let element" data-label="Kürzel"> {{element.kuerzel}} </td>
  </ng-container>
  <ng-container matColumnDef="name">
     <th mat-header-cell *matHeaderCellDef  mat-sort-header>
      <mat-form-field >
        <input placeholder="Name" matInput [formControl]="filterName"/>
      </mat-form-field>
    </th>
     <td mat-cell *matCellDef="let element" data-label="Name"> {{element.vorname}} {{element.name}}</td>
  </ng-container>
  <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        <mat-form-field >
          <input placeholder="EMail" matInput [formControl]="filterEmail"/>
        </mat-form-field>
      </th>
      <td mat-cell *matCellDef="let element" data-label="EMail"> {{element.email}}</td>
  </ng-container>
  <ng-container matColumnDef="LoginErlaubt">
    <th mat-header-cell *matHeaderCellDef>
      <mat-form-field >
        <mat-select placeholder="Login" [formControl]="filterLogin">
          <mat-option value=""></mat-option>
          <mat-option *ngFor="let source of filterValuesJaNein" [value]="source">{{source}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </th>
    <td mat-cell *matCellDef="let element" data-label="Login erlaubt"> {{getLoginErlaubt(element)}}</td>
  </ng-container>
  <ng-container matColumnDef="Erklaerung">
    <th mat-header-cell *matHeaderCellDef>
      <mat-form-field >
        <mat-select placeholder="Erklärung liegt vor" [formControl]="filterErklaerung">
          <mat-option value=""></mat-option>
          <mat-option *ngFor="let source of filterValuesJaNein" [value]="source">{{source}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </th>
    <td mat-cell *matCellDef="let element" data-label="Erklärung liegt vor"> {{getDSchutzErklaerungVorhanden(element)}}</td>
  </ng-container>
  
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="editDPMitarbeiter(row)"></tr>
 </table>
 <mat-paginator #paginator [pageSizeOptions]="[20, 50, 100, 500]"
 showFirstLastButtons 
 aria-label="Select page of elements">
</mat-paginator>
