import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DekopartnerMitarbeiter } from 'src/app/core/model/dekopartner.mitarbeiter';
import { User } from 'src/app/core/model/user';
import { DekopartnerService } from 'src/app/core/services/dekopartner.service';
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: 'app-dpmitarbeiter-bearbeiten',
  templateUrl: './dpmitarbeiter-bearbeiten.component.html',
  styleUrls: ['./dpmitarbeiter-bearbeiten.component.scss']
})
export class DpmitarbeiterBearbeitenComponent implements OnInit {

  form: UntypedFormGroup;
  user: User | null;
  errorMessage: string='';
  
  constructor(@Inject(MAT_DIALOG_DATA) public data: DekopartnerMitarbeiter,
              private fb: UntypedFormBuilder,
              private dialogRef: MatDialogRef<DpmitarbeiterBearbeitenComponent>,
              private userService: UserService,
              private dekopartnerService: DekopartnerService) { 
      this.form= fb.group({
                  zustimmung: [false, Validators.required]});
      this.user= userService.currentUserValue;
  };

  ngOnInit(): void {
  }

  
  public formAbort() {
    this.dialogRef.close();
  }

  public formSenden(form:any) {
    if (form.valid) {
      if (!form.value.zustimmung) {
        this.errorMessage= "Sie müssen das Vorliegen der Datengeheimniserklärung bestätigen."
        return;

      }

      this.dekopartnerService.sendeDPMitarbeiterZustimmung(this.data).subscribe({
        next: (response) => { if (response.success) this.dialogRef.close(true); else this.errorMessage= response.ErrorMessage; },
        error: (error) => this.errorMessage= error.ErrorMessage                
      });      
    }
  }

}
