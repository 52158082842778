import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FragebogenService } from 'src/app/core/services/fragebogen.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-fragebogen-picture',
  templateUrl: './fragebogen-picture.component.html',
  styleUrls: ['./fragebogen-picture.component.scss']
})
export class FragebogenPictureComponent implements OnInit {

  @Input()   picture : string;
  @Input()   picIndex: number;
  @Input()   canDelete: boolean;
  @Output()  notifyDeleteImage = new EventEmitter<string>();

  
  constructor() {
    this.picture= '';
    this.canDelete= false;
    this.picIndex= 0;
   }

  ngOnInit(): void {
  }

  getPictureUrl():string {
    return environment.pictureUrl+'/'+this.picture;
  }

  deletePicture(): void {
    this.notifyDeleteImage.emit(this.picture);
  }


}
