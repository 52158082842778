<mat-toolbar color="primary" fxLayout="row" fxLayoutAlign="space-between center">
  <button mat-button (click)="sidenav.toggle()" fxHide="false" fxHide.gt-sm *ngIf="currentUser && currentUser.nr">
    <mat-icon>menu</mat-icon>
  </button>
  <span>
    <button mat-button routerLink="/">{{title}}</button>
  </span>
  <span class="toolbar-spacer"></span>
  <span>{{currentUser?.name}}</span>
</mat-toolbar>
<mat-sidenav-container [hasBackdrop]="true" (click)="sidenav.close()">
  <mat-sidenav #sidenav [(opened)]="sideNavOpened" [fixedInViewport]="true" [fixedTopGap]="64" >
  <button mat-button routerLink="/login" *ngIf="!currentUser">
    <mat-icon>power_settings_new</mat-icon>
    Login
  </button>
  <button mat-button *ngIf="currentUser && currentUser.nr" (click)="logout()">
    <mat-icon>exit_to_app</mat-icon>
    Logout
  </button>
<mat-nav-list *ngIf="currentUser && currentUser.nr">
  <mat-list-item routerLink="/">
    <mat-icon mat-list-icon>home</mat-icon>
    <h4 mat-line *ngIf="isExpanded">Home</h4>
  </mat-list-item>
  <mat-list-item routerLink="/fragebogen">
    <mat-icon mat-list-icon>assignment</mat-icon>
    <h4 mat-line *ngIf="isExpanded">Fragebogen</h4>
  </mat-list-item>
  <mat-list-item routerLink="/belege" *ngIf="currentAuftrag">
        <mat-icon mat-list-icon>domain</mat-icon>
        <h4 mat-line *ngIf="isExpanded">Belege</h4>
  </mat-list-item>
  <mat-list-item routerLink="/nachrichten" *ngIf="canNachrichten()">
        <mat-icon mat-list-icon>folder_shared</mat-icon>
        <h4 mat-line *ngIf="isExpanded">Nachrichten</h4>
  </mat-list-item>
  <mat-list-item routerLink="/mitarbeiter" *ngIf="canDPMitarbeiterPflege()">
    <mat-icon mat-list-icon>people_alt</mat-icon>
    <h4 mat-line *ngIf="isExpanded">Mitarbeiter</h4>
  </mat-list-item>
</mat-nav-list>
</mat-sidenav>
<mat-sidenav-content><router-outlet></router-outlet></mat-sidenav-content>
</mat-sidenav-container>

