import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent  {
  public loginValid = true;
  public loading= false;
  public error= '';
  public username = '';
  public password = '';

  private readonly returnUrl: string;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private userService: UserService
  ) {
    this.returnUrl = this._route.snapshot.queryParams['returnUrl'] || '/game';
  }

  public onSubmit(): void {
    this.loginValid = true;
    this.loading= true;
    this.error= '';

    this.userService.login(this.username, this.password).subscribe({
      next: () => {
        const returnUrl = this._route.snapshot.queryParams['returnUrl'] || '/';
        this._router.navigate([returnUrl]);
      },
      error: error => {
        this.error= error;
        this.loading= false;
      }
    }      
    );
  }
}