import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BelegeListeComponent } from './belege/ui/belege-liste/belege-liste.component';
import { FragebogenBelegeListeComponent } from './belege/ui/fragebogen-belege-liste/fragebogen-belege-liste.component';
import { ConfirmDeactivateGuard } from './belege/ui/fragebogen-erfassung/confirm.deactivate.guard';
import { FragebogenErfassungComponent } from './belege/ui/fragebogen-erfassung/fragebogen-erfassung.component';
import { DpmitarbeiterListeComponent } from './dpmitarbeiter/ui/dpmitarbeiter-liste/dpmitarbeiter-liste.component';
import { HomeComponent } from './home/ui/home.component';
import { NachrichtenListeComponent } from './nachrichten/ui/nachrichten-liste/nachrichten-liste.component';
import { LoginComponent } from './user/ui/login/login.component';
import { AuthGuard } from './user/_helper/auth.guard';

const routes: Routes = [

  { path: '', component: HomeComponent, canActivate: [AuthGuard] },
  { path: 'nachrichten', component: NachrichtenListeComponent, canActivate: [AuthGuard]},
  { path: 'belege', component: BelegeListeComponent, canActivate: [AuthGuard]},
  { path: 'mitarbeiter', component: DpmitarbeiterListeComponent, canActivate: [AuthGuard]},
  { path: 'fragebogen', component: FragebogenBelegeListeComponent, canActivate: [AuthGuard]},
  { path: 'fragebogen/:belegid', component: FragebogenErfassungComponent, canActivate: [AuthGuard], canDeactivate:[ConfirmDeactivateGuard]},
  { path: 'extern/fragebogen/:belegid', component: FragebogenErfassungComponent},
  { path: 'login', component: LoginComponent },

  // otherwise redirect to home
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
