import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Observable } from 'rxjs';
import { DekoBeleg } from 'src/app/core/model/dekoBeleg';
import { AuftraegeService } from 'src/app/core/services/auftraege.service';
import { DekobelegeService } from 'src/app/core/services/dekobelege.service';

@Component({
  selector: 'app-belege-liste',
  templateUrl: './belege-liste.component.html',
  styleUrls: ['./belege-liste.component.scss']
})
export class BelegeListeComponent implements OnInit, AfterViewInit {

  _belege?: Observable<DekoBeleg[]>;
  datasource= new MatTableDataSource<DekoBeleg>();
  displayedColumns= ['Belegnummer', 'DekostelleNr', 'Name', 'Adresse', 'Status'];
  @ViewChild('paginator') paginator: MatPaginator | null;

  constructor(private dekobelegeService: DekobelegeService, private auftraegeService: AuftraegeService) { 
    let nr= auftraegeService.getCurrentAuftrag()?.nr;
    if (nr) 
      this._belege= dekobelegeService.getAuftragDekobelege(nr);
    this.paginator= null;
  }

  ngAfterViewInit() {
    this.datasource.paginator = this.paginator;
  }


  ngOnInit(): void {
    this._belege?.subscribe(belege => 
      this.datasource.data= belege);    
  }

  public anschriftHtml(beleg:DekoBeleg):string {
    return this.dekobelegeService.anschriftHtml(beleg);
  }

  public getStatus(beleg:DekoBeleg):string {
    return this.dekobelegeService.getStatus(beleg);
  }

}
