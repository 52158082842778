export enum NachrichtenKategorieTag { nkt_all=0, nkt_auftrag=1, nkt_dekobeleg=2, nkt_dp=3, nkt_ma=4};

export interface NachrichtKategorie {
    nr:NachrichtenKategorieTag,
    bezeichnung:string
}

export const NachrichtenKategorienSup:NachrichtKategorie[] = [ 
    {nr:NachrichtenKategorieTag.nkt_all, bezeichnung:'an alle Mitarbeiter'},
    {nr:NachrichtenKategorieTag.nkt_auftrag, bezeichnung:'an Auftrag'},
    {nr:NachrichtenKategorieTag.nkt_dekobeleg, bezeichnung:'an Dekobeleg'},
    {nr:NachrichtenKategorieTag.nkt_dp, bezeichnung:'an alle Mitarbeiter eines Dekopartners'},
    {nr:NachrichtenKategorieTag.nkt_ma, bezeichnung:'an einen Mitarbeiter'}
];

export const NachrichtenKategorienDP:NachrichtKategorie[] = [ 
    {nr:NachrichtenKategorieTag.nkt_dekobeleg, bezeichnung:'an Dekobeleg'},
    {nr:NachrichtenKategorieTag.nkt_dp, bezeichnung:'an alle Mitarbeiter des Dekopartners'},
    {nr:NachrichtenKategorieTag.nkt_ma, bezeichnung:'an einen Mitarbeiter'}
];
    


