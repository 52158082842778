import { Component, OnInit } from '@angular/core';
import { Form, UntypedFormGroup, UntypedFormArray } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { DekoBeleg } from 'src/app/core/model/dekoBeleg';
import { Fragebogen } from 'src/app/core/model/fragebogen';
import { FragebogenAntwort } from 'src/app/core/model/fragebogen.antwort';
import { DekobelegeService } from 'src/app/core/services/dekobelege.service';
import { DekopartnerService } from 'src/app/core/services/dekopartner.service';
import { FragebogenService } from 'src/app/core/services/fragebogen.service';
import { UserService } from 'src/app/core/services/user.service';
import { ConfirmationDialogModel } from 'src/app/core/ui/confirmation-dialog/confirmation-dialog';
import { ConfirmationDialogComponent } from 'src/app/core/ui/confirmation-dialog/confirmation-dialog.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-fragebogen-erfassung',
  templateUrl: './fragebogen-erfassung.component.html',
  styleUrls: ['./fragebogen-erfassung.component.scss']
})
export class FragebogenErfassungComponent implements OnInit {
  currentBelegNr:string;
  currentFragebogen?:Fragebogen;
  currentDekobeleg?:DekoBeleg;
  fragebogenForm:UntypedFormGroup;
  fragen:UntypedFormArray;
  backToDekomanager?:string; // beinhaltet den Link zum Dekomanager zurück...
  
  constructor(private fragebogenService: FragebogenService,
              private dekobelegeService: DekobelegeService, 
              private userService: UserService,             
              private router: Router,
              private route: ActivatedRoute,
              private dialog: MatDialog) {    
    this.fragen= new UntypedFormArray([])                
    this.fragebogenForm = new UntypedFormGroup({fragen: this.fragen});
    this.currentBelegNr= '';
    this.route.params.subscribe( params => this.doLoad(params['belegid']));    
   }

  ngOnInit(): void {
    var url = new URL(window.location.href);
    const backParam  = url.searchParams.get("Backto");    
    if (backParam)
      this.backToDekomanager= backParam;        
  }

  public addFrage(frage :UntypedFormGroup) {
    this.fragen.push(frage);
  }

  evalGruppenwechsel(fbogen:Fragebogen) {
    let lastgrp:string='';
    for (let frage of fbogen.fragen) {
        if (frage.gruppe!=lastgrp) {
            lastgrp= frage.gruppe;
            frage.isGruppenWechsel= true;
        }

    }
  }  

  private doLoad(belegnr: string):void {
    this.currentBelegNr= belegnr;
    this.fragebogenService.getFragebogen(belegnr).subscribe(
      {next : fbogen => { this.evalGruppenwechsel(fbogen); this.currentFragebogen= fbogen; }}
    );
    this.dekobelegeService.getDekobeleg(belegnr).subscribe(
      {next: beleg => this.currentDekobeleg= beleg}
    );
  }

  public onSave(): void {
    this.doSave(false);
  }

  public onSubmitted(): void {
    this.doSave(true); 
  }

  public onCancel(): void {
    this.exitFragebogenErfassung();
  }

  private doSave(andQuit:boolean):void {
    if (!this.currentFragebogen || !this.currentDekobeleg) {
      alert("Fragebogen wurde nicht richtig geladen.");
      return;

    }

    if (andQuit && !this.fragebogenForm.valid) {
      alert("Es wurden noch nicht alle Fragen beantwortet.");
      return;
    }
    console.log(this.fragebogenForm.value);
        
    this.fragebogenService.saveFragebogen(this.currentBelegNr, 
      { dekobeleg: this.currentDekobeleg?.nr, 
        dekostelle: this.currentDekobeleg?.dekostelle,
        auftrag: this.currentDekobeleg?.auftrag,
        fragebogen: this.currentFragebogen?.nr, 
        fragebogenFertig: andQuit,
        antworten: this.fragebogenForm.value.fragen })
      .subscribe(
      { next : (item) => { if (item.success) {
                              this.fragebogenForm.markAsPristine();
                              this.afterSaved(andQuit);         
                            }  
                           else {alert('Fehler beim Speichern der Fragebogendaten')}
                         },
       error : (error) => { console.log(error); alert('Fehler beim Speichern der Fragebogendaten: '+error)}
    }
    );

  }

  private afterSaved(doQuit:boolean) :void {
    const dialogData = new ConfirmationDialogModel('', 'Die Antworten wurden gespeichert.', false);
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        maxWidth: '400px',
        closeOnNavigation: true,
        data: dialogData
    })

    dialogRef.afterClosed().subscribe(dialogResult => {
        if (dialogResult && doQuit) {
          this.exitFragebogenErfassung();
        }
    });    
  }

  private exitFragebogenErfassung(): void {
    if (!this.backToDekomanager) {
      this.router.navigate(['/fragebogen']);
      return;
    }
    if ((this.backToDekomanager=='Leist') && this.currentDekobeleg) {
      let lnk= `${environment.dekomanagerUrl}/Leistungserf_det.htm?adnr=`+this.currentDekobeleg?.nr;
      window.location.href= lnk;
      return;
    }
    if (this.backToDekomanager.startsWith('DS') && this.currentDekobeleg) {
      let lnk= `${environment.dekomanagerUrl}/Dekostelle_det.htm?DSNR=`+this.currentDekobeleg?.dekostelle;
      window.location.href= lnk;
      return;
    }

  }

  public hasChanges(): boolean {
    return this.fragebogenForm.dirty;
  }

  public canPermalink(): boolean {
    if (!this.currentFragebogen)
      return false;
    return this.userService.canFragebogenPermalink();
  }

  public getPermalink(): string {
    return 'https://app.dekomanager.de/extern/fragebogen/'+this.currentFragebogen?.permalink;
  }


}
