import { HttpEventType, HttpResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { DekoBeleg } from 'src/app/core/model/dekoBeleg';
import { FragebogenFrage } from 'src/app/core/model/fragebogen.fragen';
import { FragebogenService } from 'src/app/core/services/fragebogen.service';

@Component({
  selector: 'app-fragebogen-picture-upload',
  templateUrl: './fragebogen-picture-upload.component.html',
  styleUrls: ['./fragebogen-picture-upload.component.scss']
})
export class FragebogenPictureUploadComponent implements OnInit {

  @Input() dekobeleg?: DekoBeleg;
  @Input() frage?: FragebogenFrage;

  @Output()  notifyAddImage = new EventEmitter<string>();

  selectedFiles?: FileList;
  progressInfos: any[] = [];
  message: string[] = [];
  fileInfos?: Observable<any>;

  constructor(private fragebogenService: FragebogenService) { }

  ngOnInit(): void {
  }

  selectFiles(event:any): void {
    this.message = [];
    this.progressInfos = [];
    this.selectedFiles = event.target.files;
    this.uploadFiles();
    event.target.value = null;
  }

  uploadFiles(): void {
    this.message = [];
    if (this.selectedFiles) {
      for (let i = 0; i < this.selectedFiles.length; i++) {
        this.upload(i, this.selectedFiles[i]);
      }
    }
  }

  private upload(idx: number, file: File): void {
    this.progressInfos[idx] = { value: 0, fileName: file.name };
    if (!this.dekobeleg?.nr || !this.frage?.nr)
      return;

    if (file) {

      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        this.uploadJson(idx, { filename: file.name, filesize: file.size, filetype: file.type, content: fileReader.result} )
      }  
      fileReader.onerror = (error) => {
         console.log(error);
         alert(error);
      }
    }
  }

  private uploadJson(idx: number, filedata:any):void {

    if (!this.dekobeleg?.nr || !this.frage?.nr)
      return;

      this.fragebogenService.uploadPicture(this.dekobeleg?.nr, this.frage?.nr, filedata).subscribe({
        next: (event: any) => {
          if (event.type === HttpEventType.UploadProgress) {
            this.progressInfos[idx].value = Math.round(100 * event.loaded / event.total);
          } else if (event.success) {
            console.log('Upload event: ',event);
            const msg = 'Dateiupload vollständig: ' + filedata['name'];
            this.message.push(msg);
            // refresh pictures...
            this.notifyAddImage.emit(event.piclink);
          } else if (!event.success && event.ErrorMessage) {
            console.log('Upload event error: ',event);
            alert(event.ErrorMessage);
          }
        },
        error: (err: any) => {
          this.progressInfos[idx].value = 0;
          const msg = 'Fehler beim Upload von Datei: ' + filedata.filename;
          this.message.push(msg);
        }
      });
    
  }

}
