<div [formGroup]="fragebogenGroup">
<div class="frage fragetyp0" *ngIf="frage?.antwortTyp==0">
  <mat-radio-group
    name="antwortRadio{{frage?.nr}}"
    formControlName="antwort">
    <mat-radio-button class="my-radio-group" *ngFor="let aw of getAntwortAuswahl()" [value]="aw" color="accent" [disabled]="fragebogen?.readonly">
      {{aw}}
    </mat-radio-button><br>
</mat-radio-group>
</div>

<div class="frage fragetyp2" *ngIf="frage?.antwortTyp==2">
   <input class="text" matInput placeholder="Ihre Antwort" formControlName="antwort" name="antwortText{{frage?.nr}}" [readonly]="fragebogen?.readonly">
</div>

<div class="frage fragetyp3" *ngIf="frage?.antwortTyp==3">
  <span>{{getSplitEinheit(1)}}</span>
  <input class="zahl" matInput placeholder="Ihre Antwort" formControlName="antwort" name="antwortText{{frage?.nr}}" [readonly]="fragebogen?.readonly">
  <span>{{getBaseEinheit()}}</span>
</div>

<div class="frage fragetyp5" *ngIf="frage?.antwortTyp==5">  
   <mat-checkbox *ngFor="let aw of getAntwortAuswahl()" [value]="aw" color="accent" class="my-checkbox-group" [disabled]="fragebogen?.readonly"
                 [checked]="getAuswahlChecked(aw)" 
                 (change)="onChangeMultiselect($event, aw)">{{aw}}</mat-checkbox>
</div>

<div class="frage fragetyp10"*ngIf="frage?.antwortTyp==10">   
  <textarea matInput cdkTextareaAutosize [readonly]="fragebogen?.readonly"
      #autosize="cdkTextareaAutosize"
      cdkAutosizeMinRows="5"
      cdkAutosizeMaxRows="5"
      placeholder="Ihre Antwort"
      formControlName="antwort"
      name="antwortText{{frage?.nr}}"
      ></textarea>
</div>

<div class="frage fragetyp13" *ngIf="frage?.antwortTyp==13">
  <div class="imageanswer" gallerize="gallery-{{frage?.nr}}">
    <app-fragebogen-picture *ngFor="let pic of pictureList; index as i" 
                             [picture]="pic"
                             [picIndex]="i"
                             [canDelete]="getReadOnly()"
                             (notifyDeleteImage)="deleteImage($event)">
                             </app-fragebogen-picture>
    <app-fragebogen-picture-upload *ngIf="!fragebogen?.readonly" [dekobeleg]="dekobeleg" [frage]="frage" (notifyAddImage)="addImage($event)"></app-fragebogen-picture-upload>
  </div>
</div>

<div class="frage fragetyp14" *ngIf="frage?.antwortTyp==14">
  <div class="antwortteil">
    <span>{{getSplitEinheit(1)}}</span>
    <input class="zahl" matInput placeholder="Ihre Antwort 1" formControlName="antwortAbm1" name="antwortText{{frage?.nr}}1" (input)="antwAbmChange()" [readonly]="fragebogen?.readonly">
  </div>
  <div class="antwortteil">
    <span>{{getSplitEinheit(2)}}</span>
    <input class="zahl2" matInput placeholder="Ihre Antwort 2" formControlName="antwortAbm2" name="antwortText{{frage?.nr}}2" (input)="antwAbmChange()" [readonly]="fragebogen?.readonly">
    <span>{{getBaseEinheit()}}</span>
  </div>  
</div>

</div>
