<h4 mat-dialog-title>
    {{title}}
  </h4>
  
  <div mat-dialog-content>
    <p>{{message}}</p>
  </div>
  
  <div mat-dialog-actions>
    <button *ngIf="showcancel" mat-raised-button cdkFocusInitial (click)="onDismiss()">Abbrechen</button>
    <button mat-raised-button (click)="onConfirm()">OK</button>
  </div>
