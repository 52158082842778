import { HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { Fragebogen } from '../model/fragebogen';
import { FragebogenAntworten } from '../model/fragebogen.antworten';
import { FragebogenFrage } from '../model/fragebogen.fragen';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class FragebogenService {

  constructor(private apiService: ApiService) { }

  // DekoBelegnr: kann auch Permalink-ID sein.
  public getFragebogen(DekobelegNr: string) : Observable<Fragebogen> {
    return this.apiService.get("/fragebogen/"+DekobelegNr)
      .pipe(
        map( item => 
                ({nr: item['fragebogen']['NR'],
                  bezeichnung: item['fragebogen']['BEZ'],
                  version: item['fragebogen']['VERSION'],
                  permalink: item['fragebogen']['PERMALINK'],
                  readonly: item['fragebogen']['READONLY']==1,
                  fragen : item['fragen'].map( (f:[]) => this.mapFrage(f))
                } as Fragebogen
                )
              
            )
      )       
  }

  public saveFragebogen(DekobelegNr: string, antworten: FragebogenAntworten) {
    return this.apiService.post("/fragebogen/"+DekobelegNr, antworten);
  }

  private mapFrage(item: any):FragebogenFrage {
    return {
      nr: item['NR'],
      seite: item['SEITE'],
      gruppe: item['M_GRP'],
      fragenr: item['FRAGENR'],
      frage: item['FRAGE'],
      einheit: item['EINHEIT'],
      antwortTyp: item['ANTW_TYP'],
      antwortAuswahl: item['ANTW_AUSWAHL'],
      antwort: item['ANTW_TEXT'],
      antwortPicList: item['ANTW_PICLIST']
    } as FragebogenFrage;
  }

  public uploadPicture(dekobelegNr: number, frageNr:number, filedata:Object): Observable<HttpEvent<any>>  {
    return this.apiService.uploadFile('/fragebogen/'+dekobelegNr+'/'+frageNr+'/upload', filedata);
  }

  public deletePicture(dekobelegNr: number, frageNr:number, bildlink:string) {
    return this.apiService.delete(`/fragebogen/${dekobelegNr}/${frageNr}/foto/${bildlink}`);
  }

}
