<h1>Belege</h1>

<table mat-table [dataSource]="datasource" class="mat-elevation-z8">

   <!--- Note that these columns can be defined in any order.
         The actual rendered columns are set as a property on the row definition" -->
 
   <!-- Position Column -->
   <ng-container matColumnDef="Belegnummer">
     <th mat-header-cell *matHeaderCellDef> Belegnr. </th>
     <td mat-cell *matCellDef="let element" data-label="Belegnummer"> {{element.belegnummer}} </td>
   </ng-container>
   <ng-container matColumnDef="DekostelleNr">
      <th mat-header-cell *matHeaderCellDef> Nummer </th>
      <td mat-cell *matCellDef="let element" data-label="Nummer"> {{element.dsIntNr}}<br>{{element.dsExtNr}}</td>
    </ng-container>
    <ng-container matColumnDef="Name">
        <th mat-header-cell *matHeaderCellDef> Dekostelle </th>
        <td mat-cell *matCellDef="let element" data-label="Dekostelle"> {{element.dsName}}<br>{{element.dsName2}}</td>
    </ng-container>
    <ng-container matColumnDef="Adresse">
        <th mat-header-cell *matHeaderCellDef> Adresse </th>
        <td mat-cell *matCellDef="let element" data-label="Adresse"> {{element.dsStrasse}}<br>{{element.dsPlz}} {{element.dsOrt}}</td>
    </ng-container>
    <ng-container matColumnDef="Status">
        <th mat-header-cell *matHeaderCellDef> Status </th>
        <td mat-cell *matCellDef="let element" data-label="Status"> {{getStatus(element)}}</td>
    </ng-container>
    
   <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
   <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
 </table>
 <mat-paginator #paginator [pageSizeOptions]="[20, 50, 100, 500]"
 showFirstLastButtons 
 aria-label="Select page of periodic elements">
</mat-paginator>
