import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Observable } from 'rxjs';
import { Dekopartner } from 'src/app/core/model/dekopartner';
import { DekopartnerMitarbeiter } from 'src/app/core/model/dekopartner.mitarbeiter';
import { DekopartnerService } from 'src/app/core/services/dekopartner.service';
import { UserService } from 'src/app/core/services/user.service';
import { DpmitarbeiterBearbeitenComponent } from '../dpmitarbeiter-bearbeiten/dpmitarbeiter-bearbeiten.component';

@Component({
  selector: 'app-dpmitarbeiter-liste',
  templateUrl: './dpmitarbeiter-liste.component.html',
  styleUrls: ['./dpmitarbeiter-liste.component.scss']
})
export class DpmitarbeiterListeComponent implements OnInit, AfterViewInit {

  _mitarbeiter?: Observable<DekopartnerMitarbeiter[]>;
  datasource= new MatTableDataSource<DekopartnerMitarbeiter>();
  @ViewChild('paginator') paginator: MatPaginator | null;
  @ViewChild(MatSort) sort: MatSort= new MatSort();
  

  filterErklaerung = new UntypedFormControl('');
  filterLogin= new UntypedFormControl('');
  filterDekopartner= new UntypedFormControl('');
  filterKuerzel= new UntypedFormControl('');
  filterName= new UntypedFormControl('');
  filterEmail= new UntypedFormControl('');

  displayedColumns= ['kuerzel', 'name', 'email', 'LoginErlaubt', 'Erklaerung'];
  filterValuesJaNein= ['Ja', 'Nein'];
  filterValuesStatus= ['aktiv', 'inaktiv'];
  filterValues: any = { dekopartner: '', name: '', kuerzel: '', email: '', hatLogin: '', hatErklaerung: ''};

  constructor(private dekopartnerService: DekopartnerService, private userService: UserService, private dialog: MatDialog) { 
    
    // für den SUP die DP-Angabe mit ausgeben
    if (userService.currentUserValue?.isSupervisor())
      this.displayedColumns= ['dekopartner'].concat(this.displayedColumns);

    this._mitarbeiter= dekopartnerService.getAlleDekopartnerMitarbeiter(true);
    this.paginator= null;
  }

  ngAfterViewInit() {
    this.datasource.paginator = this.paginator;
  }

  ngOnInit(): void {
    this.reSubscribe();

    this.datasource.filterPredicate = (data: DekopartnerMitarbeiter, filter: string) => {
          let searchTerms = JSON.parse(filter);
          let ok= true;
          if (searchTerms.dekopartner!='')
            ok= ok && (data.dekopartner==searchTerms.dekopartner); // gleich
          if (searchTerms.name!='')
            ok= ok && ((data.vorname+' '+data.name).toLowerCase().indexOf(searchTerms.name.toLowerCase())!=-1); // contains
          if (searchTerms.email!='')
            ok= ok && (data.email!=null) && (data.email?.toLowerCase().indexOf(searchTerms.email.toLowerCase())!=-1); // contains
          if (searchTerms.kuerzel!='')
            ok= ok && (data.kuerzel!=null) && (data.kuerzel?.toLowerCase().indexOf(searchTerms.kuerzel.toLowerCase())==0); // startet mit 
          if (searchTerms.hatLogin!='')
            ok= ok && ((data.hatLogin ? 'Ja' : 'Nein')==searchTerms.hatLogin); // gleich
          if (searchTerms.hatErklaerung!='')
            ok= ok && (((data.dpFreigabe!=null) ? 'Ja' : 'Nein')==searchTerms.hatErklaerung); // gleich
          return ok;
       };  
    this.fieldListener();
    this.datasource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'name': return (item.vorname+' '+item.name);
        case 'kuerzel': return item.kuerzel;
        case 'email': return item.email;
        case 'dekopartner': return item.dekopartner;
        default: return '';
      }
    };
  }

  private reSubscribe(): void {
    this._mitarbeiter?.subscribe(mitarbeiter => {
      this.datasource.data= mitarbeiter;
      this.datasource.sort = this.sort;
    });  

  }

  private fieldListener() {
    this.filterDekopartner.valueChanges
      .subscribe(
        dp => {
          this.filterValues.dekopartner = dp;
          this.updateFilter();
        }
      )
    this.filterErklaerung.valueChanges
      .subscribe(
        value => {
          this.filterValues.hatErklaerung = value;
          this.updateFilter();
        }
      )
    this.filterLogin.valueChanges
      .subscribe(
        value => {
          this.filterValues.hatLogin = value;
          this.updateFilter();
        }
      )    
    this.filterEmail.valueChanges
      .subscribe(
        value => {
          this.filterValues.email = value;
          this.updateFilter();
        }
      )    
    this.filterName.valueChanges
      .subscribe(
        value => {
          this.filterValues.name = value;
          this.updateFilter();
        }
      )    
      this.filterKuerzel.valueChanges
      .subscribe(
        value => {
          this.filterValues.kuerzel = value;
          this.updateFilter();
        }
      )    
  }

  private updateFilter() {
    this.datasource.filter= JSON.stringify(this.filterValues);
  }

  public getStatus(mitarbeiter:DekopartnerMitarbeiter):string {
    return this.dekopartnerService.getMitarbeiterStatus(mitarbeiter);
  }

  public getLoginErlaubt(mitarbeiter:DekopartnerMitarbeiter):string {
    return this.dekopartnerService.getMitarbeiterLoginErlaubt(mitarbeiter);
  }

  public getDSchutzErklaerungVorhanden(mitarbeiter:DekopartnerMitarbeiter):string {
    return mitarbeiter.dpFreigabe==null ? 'Nein' : 'Ja';
  }

  public editDPMitarbeiter(mitarbeiter:DekopartnerMitarbeiter) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = mitarbeiter;

    const dialogRef = this.dialog.open(DpmitarbeiterBearbeitenComponent, dialogConfig);  
    
    dialogRef.afterClosed().subscribe(
      () => { // refresh auslösen, falls Datensatz gespeichert.
              this.reSubscribe();
      }); 
  }


}
