import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Gallery, GalleryItem, ImageItem } from 'ng-gallery';
import { Lightbox } from 'ng-gallery/lightbox';
import { DekoBeleg } from 'src/app/core/model/dekoBeleg';
import { Fragebogen } from 'src/app/core/model/fragebogen';
import { FragebogenFrage } from 'src/app/core/model/fragebogen.fragen';
import { FragebogenService } from 'src/app/core/services/fragebogen.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-fragebogen-erfassung-frage',
  templateUrl: './fragebogen-erfassung-frage.component.html',
  styleUrls: ['./fragebogen-erfassung-frage.component.scss']
})
export class FragebogenErfassungFrageComponent implements OnInit {

  @Input()   frage?: FragebogenFrage;
  @Input()   dekobeleg?: DekoBeleg;
  @Input()   fragebogen?: Fragebogen;

  @Output()  notifyAddFrage = new EventEmitter<UntypedFormGroup>();

  fragebogenGroup:UntypedFormGroup;
  antwortText:UntypedFormControl= new UntypedFormControl('');
  antwortNr:UntypedFormControl = new UntypedFormControl('');
  antwortFileSource?:UntypedFormControl;
  antwortAbm1?:UntypedFormControl;
  antwortAbm2?:UntypedFormControl;

  pictureList:string[]= [];

  constructor(private fragebogenService:FragebogenService, private gallery: Gallery, private lightbox: Lightbox) {     
    this.fragebogenGroup= new UntypedFormGroup({});
    this.fragebogenGroup.addControl('antwort', this.antwortText);    
    this.fragebogenGroup.addControl('nr', this.antwortNr);    
  }

  ngOnInit(): void {
    if (this.frage) {  
      this.antwortNr.setValue(this.frage.nr);
      if (this.frage.antwortTyp==13) {
        // file-upload: generate filesource control...
        this.antwortText.setValue(this.frage.antwortPicList);
        this.antwortFileSource= new UntypedFormControl('');
        this.fragebogenGroup.addControl('fileSource', this.antwortFileSource);  
        if (this.frage.antwortPicList) {
          this.pictureList= this.frage.antwortPicList.split(',');  
        }
      } else if (this.frage.antwortTyp==14) {
        // 2 zahlen (abmessungen)  
        this.antwortText.setValue(this.frage.antwort);
        this.antwortAbm1= new UntypedFormControl('');
        this.antwortAbm2= new UntypedFormControl('');
        this.fragebogenGroup.addControl('antwortAbm1', this.antwortAbm1);
        this.fragebogenGroup.addControl('antwortAbm2', this.antwortAbm2);
        var antworten:string[]= [];
        if (this.frage.antwort) antworten= this.frage.antwort.split('x');
        while (antworten.length<2) antworten.push('');
        this.antwortAbm1.setValue(antworten[0]);
        this.antwortAbm2.setValue(antworten[1]);
      } else
        this.antwortText.setValue(this.frage.antwort);
      this.notifyAddFrage.emit(this.fragebogenGroup);
    }
  }

  getAntwortAuswahl() :string[] {
    if (this.frage)
      return this.frage.antwortAuswahl.split('\r\n');

    return [];
  }

  // bei Fragen vom typ 3 (eine abm)/14 (zwei abm) gibt es eh(1) ___ eh(2) __ BaseEH
  // typ 3 kennt eh(2) nicht (leer). Falls eh(2) leer ist => "x" zurückgegeben
  // das feld einheit ist mit ";" getrennt
  getBaseEinheit(): string {
    if (!this.frage || !this.frage.einheit)
      return '';

    let parts:string[]= this.frage.einheit.split(';');
    return parts[parts.length-1];
  }

  getSplitEinheit(part: number): string {

    if (!this.frage || !this.frage.einheit)
      return '';
    let parts:string[]= this.frage.einheit.split(';');
    if (parts.length==2) parts.splice(1,0,'');
    while (parts.length<3)
      parts.splice(0,0, '');
    if (parts[1]=='') parts[1]= 'X';
    return parts[part-1];
  }

  onChangeMultiselect(event: any, item: string):void {
    if (!this.frage)
      return;

    let atext: string[]= [];
    if (this.antwortText.value)
      atext= this.antwortText.value.split(',');

    if (event.checked)
      if (atext.indexOf(item)==-1) atext.push(item);
    else
      atext= atext.filter(obj => obj != item);
    
    this.antwortText.setValue(atext.join(','));
  }

  getAuswahlChecked(item: string): boolean {
    if (this.frage && this.antwortText.value)
      return this.antwortText.value.split(',').indexOf(item)>-1;    
    return false;
  }

  addImage(imglink: string):void {
     let s:string = this.antwortText.value;
     if (s) s= s+","+imglink;
     else s= imglink;
     this.pictureList.push(imglink);
     this.antwortText.setValue(s);
  }

  deleteImage(imglink: string): void {
    if (!this.dekobeleg?.nr || !this.frage?.nr)
      return;

    let s= imglink.substring(0, imglink.indexOf('.')); // extension weg...
    this.fragebogenService.deletePicture(this.dekobeleg?.nr, this.frage?.nr, s).subscribe({
      next: (event: any) => {
        if (event.success) {
          console.log('delete event: ',event);
          // delete picture from list...
          this.pictureList= this.pictureList.filter(item => item !== imglink);
          this.antwortText.setValue(this.pictureList.join(','));        
        } else if (!event.success && event.ErrorMessage) {
          console.log('delete event error: ',event);
          alert(event.ErrorMessage);
        }
      },
      error: (err: any) => {
        alert('Fehler beim Löschen des Bildes: ' + err);
      }
    });
  }

  public getReadOnly(): boolean {
    return !this.fragebogen?.readonly;
  }

  public antwAbmChange(): void {
    var v:string= this.antwortAbm1?.value + 'x'+ this.antwortAbm2?.value;
    if (v=='x') v= '';
    this.antwortText.setValue(v);
  }


}
