import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Auftrag } from 'src/app/core/model/auftrag';
import { AuftraegeService } from 'src/app/core/services/auftraege.service';
import { AuthGuard } from 'src/app/user/_helper/auth.guard';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  _auftraege: Observable<Auftrag[]>;
  form: UntypedFormGroup;
  submitted: boolean;

  constructor(private fb: UntypedFormBuilder, 
              private auftraegeService : AuftraegeService,
              private authGuard: AuthGuard,
              private router : Router) { 
    this._auftraege= auftraegeService.getAlleAuftraege();
    this.submitted= false;
    this.form= fb.group({
      auftrag: [null],
    });
  }

  ngOnInit(): void {
    this.auftraegeService.currentAuftrag.subscribe({
      next : (auftrag) => {if (auftrag && this.submitted) this.router.navigate(['/belege'])}
    }
    )
  }

  public canNachrichten() : boolean {
    return this.authGuard.canNachrichten();
  }

  public onSubmit(form :any) : void {
    this.submitted= true;
    this.auftraegeService.setCurrentAuftragFromNr(form.value.auftrag);
  }

  public canFragebogen(): boolean {
    return this.authGuard.canFragebogen();
  }

  public canDPMitarbeiterPflege():boolean {
    return this.authGuard.canDPMitarbeiterPflege();
  }

}
