<h2 mat-dialog-title>{{data.vorname}} {{data.name}}</h2>
<form [formGroup]="form" (ngSubmit)="formSenden(form)">
    <mat-error *ngIf="errorMessage!=''">
        {{errorMessage}}
    </mat-error>
    <mat-dialog-content *ngIf="data.dpFreigabe!=null">
        <p>Die Datengeheimniserklärung für den Mitarbeiter {{data.vorname}} {{data.name}} liegt vor.</p>
        <p>Die Bestätigung erfolgte durch {{data.dpFreigabeName}} am {{data.dpFreigabe | date:'medium'}}</p>
    </mat-dialog-content>
    <mat-dialog-content *ngIf="data.dpFreigabe==null">
        <p>Die Datengeheimniserklärung für den Mitarbeiter liegt noch nicht vor. 
            Solange die Bestätigung nicht erfolgt ist, kann der Mitarbeiter sich nicht anmelden.</p>
          <mat-checkbox *ngIf="user?.isDekopartner()" formControlName="zustimmung" class="my-checkbox-group">Hiermit bestätige ich, {{user?.name}}, dass mir die vom Mitarbeiter {{data.vorname}} {{data.name}} unterschriebene Datengeheimniserklärung vorliegt.</mat-checkbox>
    </mat-dialog-content>
    <mat-dialog-actions>
    <button mat-dialog-close class="mat-raised-button" (click)="formAbort()">Zurück</button>
    <button *ngIf="user?.isDekopartner() && (data.dpFreigabe==null)" type="submit" class="mat-raised-button mat-primary">Speichern</button>
</mat-dialog-actions>