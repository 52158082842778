import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { map } from 'rxjs/operators';
import { Nachricht } from '../model/nachricht';
import { SimpleResponse } from '../model/simpleResponse';

@Injectable({
  providedIn: 'root'
})
export class NachrichtenService {

  constructor(private apiService: ApiService) { }

  public getNachrichten() : Observable<Nachricht[]> {
    return this.apiService.get("/nachrichten")
      .pipe(
        map(
          res => res['data']
          ),
        map( (items:[]) =>
              items.map(item => 
                ({nr: item['NR'],
                  text: item['TEXT'],
                  titel: item['TITEL'],
                  wichtig: item['WICHTIG']==1,
                  kategorie: item['KATEGORIE'],
                  gesendetAm: item['GESENDETAM'],
                  absenderName: item['ABSENDERNAME']                  
                } as Nachricht
                )
              )
            )
      )       
  }

  public sendeNachricht(nachricht: Nachricht): Observable<SimpleResponse> {

    return this.apiService.post('/nachrichten', nachricht);

  }
}
