export class User {

    static USER_FUNKTION_SUPERVISOR:bigint=BigInt(1);
    static USER_FUNKTION_DEKOPARTNER:bigint=BigInt(2);
    static USER_FUNKTION_DEKOPARTNER_MITARBEITER:bigint=BigInt(6);
    static USER_FUNKTION_WITHOUTLOGIN:bigint=BigInt(99);

    nr : bigint;
    name : string;
    funktion : bigint;
    email : string;
    token : string;
    demaUser: boolean;

    constructor(nr:bigint, name:string, funktion:bigint, email:string, token:string) {
        this.nr= nr;
        this.name= name;
        this.funktion= funktion;
        this.email= email;
        this.token= token;
        this.demaUser= false;
    };

    static Empty():User {
        return new User(BigInt(0), '', BigInt(0), '', '');
    }

    public isSupervisor():boolean {
        return this.funktion== User.USER_FUNKTION_SUPERVISOR;
    }

    public isDekopartner():boolean {
        return this.funktion== User.USER_FUNKTION_DEKOPARTNER;
    }

    public isDekopartnerMitarbeiter():boolean {
        return this.funktion== User.USER_FUNKTION_DEKOPARTNER_MITARBEITER;
    }

    public isDemaUser():boolean {
        return this.demaUser;
    }

}