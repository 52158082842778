import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiService } from './api.service';
import { map } from 'rxjs/operators';
import { Auftrag } from '../model/auftrag';
import { DekoBeleg } from '../model/dekoBeleg';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AuftraegeService {

  private _currentAuftragSubject: BehaviorSubject<Auftrag | null>;
  private _currentAuftrag: Auftrag | null;
  public currentAuftrag: Observable<Auftrag | null>;


  constructor(private apiService: ApiService) {
    this._currentAuftrag= null;
    this._currentAuftragSubject = new BehaviorSubject<Auftrag | null>(this._currentAuftrag);
    this.currentAuftrag = this._currentAuftragSubject.asObservable();
   }

  public setCurrentAuftragFromNr(aId : number ) : void {
    this.apiService.get("/auftrag/"+aId).subscribe({
      next: (auftragResponse) => this.processAuftragResponse(auftragResponse),
      error: (e) => { console.error(e); } 
    });      
  }

  public getCurrentAuftrag():Auftrag | null {
    return this._currentAuftrag;
  }

  private processAuftragResponse (auftragResponse:any) : void {
    if (auftragResponse.success) {
      this._currentAuftrag= this.mapitem(auftragResponse.result);
      this._currentAuftragSubject.next(this._currentAuftrag);
    }  

  }

  private mapitem(item: any): Auftrag {
    return ({nr: item['NR'],
      bezeichnung: item['BEZEICHNUNG'],
      auftragsnummer: item['AUFTRAGSNUMMER'],
      abgeschlossen: item['ABGESCHLOSSEN']==1,
      laufzeitVon: item['LAUFZEIT_BIS'],
      laufzeitBis: item['LAUFZEIT_VON']
     } as Auftrag
    );    
  }

  public getAlleAuftraege(): Observable<Auftrag[]> {
    return this.apiService.get("/auftrag")
      .pipe(
        map(
          res => res['data']
          ),
        map( (items:[]) =>
              items.map(item => this.mapitem(item)
              )
            )
      )       
  }

  public getOffeneAuftraege() : Observable<Auftrag[]> {
    const params = new HttpParams().set('filter', 'offen,laufend');
    return this.apiService.get("/auftrag", params)
      .pipe(
        map(
          res => res['data']
          ),
        map( (items:[]) =>
              items.map(item => 
                ({nr: item['NR'],
                  bezeichnung: item['BEZEICHNUNG'],
                  auftragsnummer: item['AUFTRAGSNUMMER'],
                  abgeschlossen: item['ABGESCHLOSSEN']==1,
                  laufzeitVon: item['LAUFZEIT_BIS'],
                  laufzeitBis: item['LAUFZEIT_VON']
                } as Auftrag
                )
              )
            )
      )       
  }

  
}
