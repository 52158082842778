import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { User } from 'src/app/core/model/user';
import { UserService } from 'src/app/core/services/user.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private userService: UserService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const currentUser = this.userService.currentUserValue;
        if (currentUser) {            
            // logged in
            // nachrichten und User nicht supervisor oder dekopartner? => kein Zugriff
            if ((!currentUser.isSupervisor()) && (!currentUser.isDekopartner()) && ((state.url=='/nachrichten') || (state.url=='/mitarbeiter')))
              return false;
              
            return true;
        }

        // not logged in so redirect to login page with the return url
        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
        return false;
    }

    canNachrichten() : boolean {
        const currentUser=this.userService.currentUserValue;
        if (!currentUser) 
          return false;

        if ((currentUser.isSupervisor()) || (currentUser.isDekopartner())) 
          return true;

        return false;
    }

    canFragebogen() : boolean {
      const currentUser=this.userService.currentUserValue;
      if (!currentUser) 
        return false;
      if ( currentUser.isDekopartner() || currentUser.isDekopartnerMitarbeiter() || currentUser.isSupervisor()) 
        return true;

      return false;
    }

    canDPMitarbeiterPflege(): boolean {
      const currentUser=this.userService.currentUserValue;
      if (!currentUser) 
        return false;
      if ( currentUser.isDekopartner() || currentUser.isSupervisor()) 
        return true;

      return false;
    }
}