<h1>{{currentFragebogen?.bezeichnung}}</h1>
<section *ngIf="currentDekobeleg">
    <div *ngIf="currentDekobeleg.nr; else noAccess">
    Dekostelle:<br>
    {{currentDekobeleg.dsName}}<br>{{currentDekobeleg.dsStrasse}}<br>{{currentDekobeleg.dsPlz}} {{currentDekobeleg.dsOrt}}
  </div>
  <ng-template #noAccess>
    Fehler beim Zugriff auf die Dekostelle oder den Fragebogen.
  </ng-template>
</section>

<form [formGroup]="fragebogenForm" (ngSubmit)="onSubmitted()">

<div *ngFor="let frage of currentFragebogen?.fragen">
  <mat-card *ngIf="frage.isGruppenWechsel" class="GruppenWechsel">
    <mat-card-title>
      {{frage.gruppe}}
    </mat-card-title>    
  </mat-card>
  <mat-card>  
    <mat-card-title>{{frage.frage}}</mat-card-title>
    <mat-card-content>
      <app-fragebogen-erfassung-frage [fragebogen]= "currentFragebogen" [frage]="frage" [dekobeleg]="currentDekobeleg" (notifyAddFrage)="addFrage($event)"></app-fragebogen-erfassung-frage>
    </mat-card-content>
  </mat-card>
</div>

<mat-card>  
  <mat-card-title>      
  </mat-card-title>
  <mat-card-content class="myfooter">
    <div *ngIf="currentFragebogen && !currentFragebogen?.readonly; else noSave">      
      <button mat-raised-button type="submit">Speichern <span *ngIf="backToDekomanager">&amp; zurück</span></button>
    </div>
    <ng-template #noSave>
      <button mat-raised-button mat-primary type="button" (click)="onCancel()">zurück</button>
    </ng-template>
  </mat-card-content>
</mat-card>


<mat-card >
  <mat-card-title>      
  </mat-card-title>
  <mat-card-content class="myfooterlinks">
     Permalink: {{getPermalink()}}
  </mat-card-content>

</mat-card>

<div class="saveButton" *ngIf="currentFragebogen && !currentFragebogen?.readonly && fragebogenForm.dirty">  
    <button color="accent" mat-mini-fab type="button" (click)="onSave()" matTooltip="Änderungen zwischenspeichern"><mat-icon>save</mat-icon></button>
</div>  

</form>

