export class Nachricht {
    nr: number=0;
    text: String='';
    titel: String='';
    link: String='';
    wichtig: boolean=false;
    kategorie: number=0;
    gesendetAm: Date=new Date;
    auftrag?: number;
    dekopartner?: number;
    dekobeleg?: number;
    dekopartnermitarbeiter?: number;
    absenderName?:string;
}