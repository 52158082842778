<form [formGroup]="form" (ngSubmit)="onSubmit(form)">

<h1>Hauptauswahl</h1>
<mat-card>
    <mat-card-title></mat-card-title>
    <mat-card-content>
    <mat-form-field name="selauftrag">
        <mat-select 
                placeholder="Auftrag"
                formControlName="auftrag">
                <mat-option *ngFor="let a of _auftraege | async" [value]="a.nr">
                    {{a.auftragsnummer+'-'+a.bezeichnung}}
                  </mat-option>
                </mat-select>
    </mat-form-field>&nbsp;
    
    <button type="submit" class="mat-raised-button">Öffnen</button>
    
</mat-card-content>
</mat-card>
<mat-card *ngIf="canFragebogen()">
    <mat-card-title></mat-card-title>
    <mat-card-content>
        <button type="button" class="mat-raised-button"  routerLink="/fragebogen">offenen Fragebogen beantworten</button>
    </mat-card-content>
</mat-card>
<mat-card *ngIf="canNachrichten()">
    <mat-card-title></mat-card-title>
    <mat-card-content>
        <button type="button" class="mat-raised-button" routerLink="/nachrichten">Nachrichten einsehen und schreiben</button>
    </mat-card-content>
</mat-card>
<mat-card *ngIf="canDPMitarbeiterPflege()">
    <mat-card-title></mat-card-title>
    <mat-card-content>
        <button type="button" class="mat-raised-button" routerLink="/mitarbeiter">Mitarbeiter pflegen</button>
    </mat-card-content>
</mat-card>
</form>