import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { MatToolbarModule } from '@angular/material/toolbar';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSelectModule } from '@angular/material/select';
import { MatOptionModule } from '@angular/material/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatDialogModule } from '@angular/material/dialog';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginComponent } from './user/ui/login/login.component';
import { JwtInterceptor } from './user/_helper/jwt.interceptor';
import { ErrorInterceptor } from './user/_helper/error.interceptor';
import { HomeComponent } from './home/ui/home.component';
import { AuthGuard } from './user/_helper/auth.guard';
import { NachrichtenService } from './core/services/nachrichten.service';
import { ApiService } from './core/services/api.service';
import { NachrichtenListeComponent } from './nachrichten/ui/nachrichten-liste/nachrichten-liste.component';
import {MatRadioModule} from '@angular/material/radio';
import { MatPaginatorModule } from '@angular/material/paginator';
import { LOCALE_ID } from '@angular/core';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import { registerLocaleData } from '@angular/common';
import { NachrichtenNeuComponent } from './nachrichten/ui/nachrichten-neu/nachrichten-neu.component';
import { UserService } from './core/services/user.service';
import { AuftraegeService } from './core/services/auftraege.service';
import { DekopartnerService } from './core/services/dekopartner.service';
import { BelegeListeComponent } from './belege/ui/belege-liste/belege-liste.component';
import { FragebogenBelegeListeComponent } from './belege/ui/fragebogen-belege-liste/fragebogen-belege-liste.component';
import { FragebogenErfassungComponent } from './belege/ui/fragebogen-erfassung/fragebogen-erfassung.component';
import { FragebogenErfassungFrageComponent } from './belege/ui/fragebogen-erfassung-frage/fragebogen-erfassung-frage.component';
import { ConfirmationDialogComponent } from './core/ui/confirmation-dialog/confirmation-dialog.component';
import { ConfirmDeactivateGuard } from './belege/ui/fragebogen-erfassung/confirm.deactivate.guard';
import { FragebogenPictureComponent } from './belege/ui/fragebogen-picture/fragebogen-picture.component';
import { FragebogenPictureUploadComponent } from './belege/ui/fragebogen-picture-upload/fragebogen-picture-upload.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { GalleryModule } from  'ng-gallery';
import { LightboxModule } from  'ng-gallery/lightbox';
import { DpmitarbeiterListeComponent } from './dpmitarbeiter/ui/dpmitarbeiter-liste/dpmitarbeiter-liste.component';
import { MatSortModule } from '@angular/material/sort';
import { DpmitarbeiterBearbeitenComponent } from './dpmitarbeiter/ui/dpmitarbeiter-bearbeiten/dpmitarbeiter-bearbeiten.component';

registerLocaleData(localeDe, localeDeExtra);

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    NachrichtenListeComponent,
    BelegeListeComponent,
    NachrichtenNeuComponent,
    BelegeListeComponent,
    FragebogenBelegeListeComponent,
    FragebogenErfassungComponent,
    FragebogenErfassungFrageComponent,
    ConfirmationDialogComponent,
    FragebogenPictureComponent,
    FragebogenPictureUploadComponent,
    DpmitarbeiterListeComponent,
    DpmitarbeiterBearbeitenComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    MatToolbarModule,
    MatInputModule,
    MatCardModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatSlideToggleModule,
    MatSelectModule,
    MatOptionModule,
    MatTooltipModule,
    MatRadioModule,
    MatSidenavModule,
    MatProgressSpinnerModule,
    MatListModule,
    MatDialogModule,
    MatCheckboxModule,
    GalleryModule,
    LightboxModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: "de" },
   // { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },    
    ConfirmDeactivateGuard,
    AuthGuard,
    UserService,
    ApiService,
    NachrichtenService,
    AuftraegeService,
    DekopartnerService],
  bootstrap: [AppComponent]
})
export class AppModule { }
