import { Injectable } from "@angular/core";
import { CanDeactivate } from "@angular/router";
import { FragebogenErfassungComponent } from "src/app/belege/ui/fragebogen-erfassung/fragebogen-erfassung.component";

@Injectable()
export class ConfirmDeactivateGuard implements CanDeactivate<FragebogenErfassungComponent > {



    canDeactivate(target: FragebogenErfassungComponent ) {
        if (target.hasChanges()) {
            return window.confirm('Achtung: ungespeicherte Änderungen. Möchten Sie wirklich die Seite verlassen?')
        }
        return true;
    }

    
}